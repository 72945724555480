import React, { useMemo } from 'react';

import { AnalyticsContext } from '@atlaskit/analytics-next';

import { useOnDismissDialog } from '../../../analytics/use-on-dismiss-dialog';
import { useEditionAwareness } from '../../../services/edition-awareness-context';
import { getHamsKey } from '../../../services/product-to-hams-key';
import { ModalEntryPoint } from '../shared/modal-entry-point';

import { DIALOG_CONFIG } from './dialog-config';
import entryPoint, { EntryPointParams } from './entrypoint';

export function EducationalDiscountDialog() {
	const { product, cloudId } = useEditionAwareness();

	const onClose = useOnDismissDialog({ ...DIALOG_CONFIG });
	const entryPointProps = useMemo(() => ({ onClose }), [onClose]);
	const entryPointParams: EntryPointParams = useMemo(
		() => ({ cloudId, hamsProductKey: getHamsKey(product) }),
		[cloudId, product],
	);

	const analyticsAttributes = useMemo(() => ({ ...DIALOG_CONFIG }), []);

	return (
		<AnalyticsContext data={analyticsAttributes}>
			<ModalEntryPoint
				entryPoint={entryPoint}
				entryPointParams={entryPointParams}
				entryPointProps={entryPointProps}
			/>
		</AnalyticsContext>
	);
}
