import React, { type PropsWithChildren, useCallback } from 'react';

import { ErrorBoundary as BaseErrorBoundary } from 'react-error-boundary';

import { useInteractionContext } from '@atlaskit/react-ufo/interaction-context';
import { addError, addErrorToAll, getActiveInteraction } from '@atlaskit/react-ufo/interaction-metrics';

import { useErrorAnalytics } from '../analytics/use-error-analytics';
import type { Edition, Product } from '../types';

type ErrorBoundaryProps = PropsWithChildren<{
	product: Product;
	edition: Edition;
	id?: string;
}>;

export const ErrorBoundary = ({ children, product, edition, id }: ErrorBoundaryProps) => {
	const handleOnError = useErrorAnalytics({ product, edition, id });

	const ufoContext = useInteractionContext();
	
	const onError = useCallback((error: Error) => {
		const interaction = getActiveInteraction();

		handleOnError(error);
		if (interaction && ufoContext) {
			addError(
				interaction?.id,
				error.name,
				ufoContext?.labelStack,
				'interaction error',
				error.message,
				error.stack
			);
		} else {
			addErrorToAll(
				`ErrorBoundary:${id}`,
				ufoContext?.labelStack || null,
				'interaction error',
				error.message,
			);
		}
	}, [handleOnError, id, ufoContext]);

	return (
		<BaseErrorBoundary onError={onError} fallback={<></>}>
			{children}
		</BaseErrorBoundary>
	);
};